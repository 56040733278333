import React from 'react'
import ServiceSidebar from './ServiceSidebar'
import details1 from '../../../assets/images/services/services-details1.jpg'
import project2 from '../../../assets/images/projects/project2.jpg'
import charts from '../../../assets/images/services/charts.jpg'

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">Soluții personalizate</span>
                            <h3>Despre testare și asigurarea calității</h3>
                            <p>Testarea software este o parte esențială a procesului de dezvoltare software. Asigură faptul că software-ul îndeplinește specificațiile necesare, funcționează corect și este de calitate înaltă. La Vertical Digital, oferim servicii comprehensive de testare software pentru a vă asigura că software-ul dvs. este fiabil, eficient și ușor de utilizat.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Procesul nostru</h3>
                                        <p>
                                            Procesul nostru de testare software include o varietate de metode și tehnici de testare pentru a asigura o acoperire completă a software-ului. Începem prin a lucra cu dvs. pentru a defini obiectivele de testare și a dezvolta un plan de testare. Apoi, executăm planul de testare, care poate include testarea unitară, testarea integrării, testarea sistemului și testarea acceptării.

                                            În timpul testării, utilizăm o varietate de instrumente și tehnici pentru a simula diferite scenarii și a identifica orice defecte sau probleme în software. Documentăm concluziile și oferim rapoarte detaliate cu privire la rezultatele testării. De asemenea, lucrăm cu dvs. pentru a aborda orice probleme identificate și pentru a vă asigura că software-ul îndeplinește standardele dvs. de calitate.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <p>La Vertical Digital, suntem dedicați furnizării de servicii de testare software de calitate înaltă pentru a vă asigura că software-ul dvs. este fiabil și eficient. Echipa noastră experimentată de testeri utilizează o varietate de metode și instrumente de testare pentru a evalua complet software-ul dvs. și a identifica orice probleme. Contactați-ne astăzi pentru a afla mai multe despre cum putem contribui la succesul proiectului dvs. de software.</p>
                            <h3>Arii de aplicabilitate</h3>

                            <div className="row">
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-factory"></i>
                                        </div>
                                        Producție
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-hospital"></i>
                                        </div>
                                        Sănătate
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-tracking"></i>
                                        </div>
                                        Automotive
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-investment"></i>
                                        </div>
                                        Banking
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-house"></i>
                                        </div>
                                        Imobiliare
                                    </div>
                                </div>
            
                                <div className="col-lg-4 col-sm-6 col-md-6">
                                    <div className="single-industries-serve-box">
                                        <div className="icon">
                                            <i className="flaticon-order"></i>
                                        </div>
                                        Logistică
                                    </div>
                                </div>
                            </div>

                            <h3>Tehnologii folosite de noi</h3>
                            <ul className="technologies-features">
                                <li><span>Java</span></li>
                                <li><span>JavaScript</span></li>
                                <li><span>Python</span></li>
                                <li><span>Selenium</span></li>
                                <li><span>Cypress</span></li>
                                <li><span>JMeter</span></li>
                                <li><span>Postman</span></li>
                                <li><span>SQL</span></li>
                                <li><span>Jenkins</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default ServiceDetailsContent